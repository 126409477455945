const SPINA_API_KEY = import.meta.env.VITE_SPINA_API_KEY;
const authorizationToken = `Token ${SPINA_API_KEY}`;

async function fetchPage(url: string) {
  try {
    const response = await fetch(url, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch page info: ${response.statusText}`);
    }

    return await response.json();
  } catch (error: any) {
    console.error('Error fetching page:', error.message);
    return null;
  }
}

async function getAllPages(initialUrl: string): Promise<any[]> {
  let allData: any[] = [];
  let currentUrl = initialUrl;

  while (currentUrl) {
    const pageData = await fetchPage(currentUrl);

    if (!pageData) {
      break;
    }

    // Add current page's data to our collection
    if (pageData.data) {
      allData = [...allData, ...pageData.data];
    }

    // Check if there's a next page
    currentUrl = pageData.links?.next || null;
  }

  return allData;
}

export default async function getPageInfo(name: string) {
  const initialUrl = '/api/pages.json';

  try {
    const allPages = await getAllPages(initialUrl);
    const matchingPage = allPages.find((page: any) => page.attributes.view_template === name);

    return matchingPage?.attributes?.content || [];
  } catch (error: any) {
    console.error('Error fetching page info:', error.message);
    return []; // Return an empty array in case of an error
  }
}
