const SPINA_API_KEY = import.meta.env.VITE_SPINA_API_KEY;
const authorizationToken = `Token ${SPINA_API_KEY}`;

export default async function fetchImageUrl(imageId: number) {
  try {
    const response = await fetch(`/api/images/${imageId}.json`, {
      headers: {
        Authorization: authorizationToken,
      },
    });

    if (!response.ok) {
      throw new Error(`Failed to fetch image with ID ${imageId}: ${response.statusText}`);
    }

    const data = await response.json();
    return data?.data?.attributes?.original_url || '';
  } catch (error: any) {
    console.error(error.message);
    return ''; // Return an empty string if image fetching fails
  }
}
